import { qimaIsOptionalTrueStringBoolean } from '../is-optional-true-string-boolean/qima-is-optional-true-string-boolean';
/**
 * @description
 * This "utils" function should be used to convert @Inputs with multiple types to boolean
 * When using @Inputs with shortcuts syntax,
 * This is better to allow passing multiple attributes syntax
 * Some of them are better for performances and to apprehend the code
 * Conversion table:
 * undefined      = true
 * null           = true
 * ''             = true
 * true           = true
 * false          = false
 * true (string)  = true
 * false (string) = false
 * @example
 * This is useful for syntax like:
 * <qima-x xIsDisabled></qima-x>
 * <qima-x xIsDisabled=""></qima-x>
 * <qima-x xIsDisabled="true"></qima-x>
 * <qima-x xIsDisabled="false"></qima-x>
 * <qima-x [xIsDisabled]="true"></qima-x>
 * <qima-x [xIsDisabled]="false"></qima-x>
 * @param {Readonly<QimaOptionalType<QimaStringBooleanType>>} isDisabled The disabled state to check
 * @returns {boolean} true when the given disabled state is really disabled.
 */
export function qimaIsDisabled(isDisabled) {
  return qimaIsOptionalTrueStringBoolean(isDisabled);
}
