import { QpConfirmationModalComponent } from '@admin/app/shared/components/qp-confirmation-modal/qp-confirmation-modal.component';
import { AdminEntitiesModule } from '@admin/app/shared/shared-entities.module';
import { AdminSharedLibsModule } from '@admin/app/shared/shared-libs.module';
import { AuthorityLabelPipe } from '@admin/app/shared/util/authority.pipe';
import { QpButtonComponent } from '@library/components/qp-button/qp-button.component';
import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { QpIconButtonComponent } from '@library/components/qp-icon-button/qp-icon-button.component';
import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule({
  imports: [
    AdminSharedLibsModule,
    AdminEntitiesModule,
    QpIconButtonComponent,
    QpIconComponent,
    QpButtonComponent,
    AuthorityLabelPipe,
    QpConfirmationModalComponent,
  ],
  exports: [AdminSharedLibsModule, AdminEntitiesModule, AuthorityLabelPipe, QpConfirmationModalComponent],
})
export class AdminSharedModule {
  public static forRoot(): ModuleWithProviders<AdminSharedModule> {
    return {
      ngModule: AdminSharedModule,
    };
  }
}
