<div class="modal-header">
  <h4 class="modal-title">{{ 'login.title' | translate }}</h4>
  <button (click)="closeModal('closed')" aria-label="Close" class="close" data-dismiss="modal" type="button">
    <span aria-hidden="true">x</span>
  </button>
</div>
<div class="modal-body">
  <div class="qp-row qp-justify-content-center">
    <div class="qp-col-md-8">
      <div *ngIf="hasAuthenticationError" class="qp-alert qp-alert-danger">
        <strong>{{ 'login.messages.error.authentication' | translate }}</strong>
      </div>
    </div>
    <div class="qp-col-md-8">
      <form (ngSubmit)="login()" class="form" role="form">
        <div class="qp-form-group">
          <label class="email-label" for="email">{{ 'global.form.email.label' | translate }} </label>
          <input
            [(ngModel)]="email"
            class="qp-form-control"
            data-cy="login"
            id="email"
            name="email"
            placeholder="{{ 'global.form.email.placeholder' | translate }}"
            type="email"
          />
        </div>
        <div class="qp-form-group">
          <label for="password">{{ 'login.form.password.label' | translate }} </label>
          <input
            [(ngModel)]="password"
            class="qp-form-control"
            data-cy="password"
            id="password"
            name="password"
            placeholder="{{ 'login.form.password.placeholder' | translate }}"
            type="password"
          />
        </div>
        <div class="form-check">
          <label class="form-check-label" for="rememberMe">
            <input [(ngModel)]="shouldRememberMe" checked class="form-check-input" id="rememberMe" name="rememberMe" type="checkbox" />
            <span>{{ 'login.form.rememberme' | translate }}</span>
          </label>
        </div>
        <button class="qp-btn qp-btn-primary" data-cy="sign-in" type="submit">{{ 'login.form.button' | translate }}</button>
      </form>
      <p></p>
      <div class="qp-alert qp-alert-warning">
        <a (click)="requestResetPassword()" class="qp-alert-link">{{ 'login.password.forgot' | translate }} </a>
      </div>
      <div class="qp-alert qp-alert-warning">
        <span>{{ 'global.messages.info.register.noaccount' | translate }}</span>
        <a (click)="register()" class="qp-alert-link">{{ 'global.messages.info.register.link' | translate }} </a>
      </div>
    </div>
  </div>
</div>
