// @ts-strict-ignore
import { QpAbstractRouterLinkComponent } from '@library/classes/qp-abstract-router-link.component';
import { QpLoaderComponent } from '@library/components/qp-loader/qp-loader.component';
import { QpStringBooleanType } from '@library/models/qp-boolean.models';
import {
  Component,
  ChangeDetectionStrategy,
  AfterContentChecked,
  Input,
  ContentChildren,
  QueryList,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

const ZERO = 0;

@Component({
  selector: 'qp-abstract-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
})
export abstract class QpAbstractButtonComponent extends QpAbstractRouterLinkComponent implements AfterContentChecked {
  public isLoading = false;

  @Input()
  public isDisabled: QimaOptionalType<QpStringBooleanType> = false;

  @ContentChildren(QpLoaderComponent)
  public content: QueryList<QpLoaderComponent>;

  @Output()
  public readonly buttonClick = new EventEmitter<MouseEvent | Event>();

  protected constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public ngAfterContentChecked(): void {
    this._setIsLoadingState();
    this._changeDetectorRef.detectChanges();
  }

  public onButtonClick(event: Readonly<MouseEvent | Event>): void {
    if (!this.isDisabled && !this.isLoading) {
      this.buttonClick.emit(event);
    }
  }

  private _setIsLoadingState(): void {
    this.isLoading = this.content.length > ZERO;
  }
}
