import { EQimaDividerOrientation } from '../enums/qima-divider-orientation.enum';
import { EQimaDividerPosition } from '../enums/qima-divider-position.enum';
import { EQimaDividerStyle } from '../enums/qima-divider-style.enum';
import { InjectionToken } from '@angular/core';
/**
 * @description
 * The token consumed by the {@link QimaDividerComponent} to define the default values of its inputs
 * @type {InjectionToken<IQimaDividerConfig>}
 */
export const QIMA_DIVIDER_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the divider component', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaDividerModule#forRoot} values
     */
    return {
      position: EQimaDividerPosition.CENTER,
      orientation: EQimaDividerOrientation.HORIZONTAL,
      style: EQimaDividerStyle.PEARL
    };
  }
});
