import { QpIconComponent } from '../qp-icon/qp-icon.component';
import { EQpIceCubeIconColor, EQpIceCubeIconSize } from '@library/components/qp-ice-cube-icon/qp-ice-cube-icon.models';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { NgClass, NgIf } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'qp-ice-cube-icon',
  templateUrl: './qp-ice-cube-icon.component.html',
  styleUrls: ['./qp-ice-cube-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, QpIconComponent],
})
export class QpIceCubeIconComponent {
  /**
   * @type {QimaOptionalType<EQpIconName>}
   * @default undefined
   */
  @Input()
  public actionIcon: QimaOptionalType<EQpIconName> = undefined;

  /**
   * @type {EQpIceCubeIconColor}
   * @default {@link EQpIceCubeIconColor.SUNSET}
   */
  @Input()
  public color: EQpIceCubeIconColor = EQpIceCubeIconColor.SUNSET;

  /**
   * @type {boolean}
   * @default true
   */
  @Input()
  public hasOutline = true;

  /**
   * @description
   * The large type should be used only for the print
   * @type {typeof EQpIceCubeIconSize}
   * @default {@link EQpIceCubeIconSize.MEDIUM}
   */
  @Input()
  public size: EQpIceCubeIconSize = EQpIceCubeIconSize.MEDIUM;

  /**
   * @type {boolean}
   * @default {false}
   */
  @Input()
  public isSpinning: boolean = false;
}
