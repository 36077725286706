export enum EQpIconButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum EQpIconButtonType {
  TRANSPARENT = 'transparent',
  HOLLOW = 'hollow',
  TRANSPARENT_DANGER = 'transparent-danger',
  POOL = 'pool',
}

export enum EQpIconButtonBorderRadiusStyle {
  ALL_SIDES = 'all',
  BOTTOM = 'bottom',
  TOP = 'top',
}
