import { LanguageHelperService } from '@admin/app/core/language/language.helper';
import { LoginModalComponent } from '@admin/app/shared/login/login.component';
import { AdminSharedLibsModule } from '@admin/app/shared/shared-libs.module';
import { QpIceCubeIconComponent } from '@library/components/qp-ice-cube-icon/qp-ice-cube-icon.component';
import { QpLabelComponent } from '@library/components/qp-label/qp-label.component';
import { QpButtonDirective } from '@library/directives/qp-button/qp-button.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    AdminSharedLibsModule,
    QpButtonDirective,
    QpIceCubeIconComponent,
    QpLabelComponent,
    TranslateModule,
    LoginModalComponent,
  ],
  exports: [LoginModalComponent],
})
export class AdminEntitiesModule {
  public constructor(translate: TranslateService, langageHelper: LanguageHelperService) {
    langageHelper.language.subscribe((langKey): void => {
      translate.use(langKey);
    });
  }
}
