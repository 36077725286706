export enum EQpIceCubeIconColor {
  // Primary UI colors
  OCEAN = 'ocean',
  POOL = 'pool',
  POOL_100 = 'pool-100',
  POOL_200 = 'pool-200',
  SUNSET = 'sunset',
  SEAL = 'seal',
  PLUM = 'plum',
  CHERRY = 'cherry',
  ORANGE = 'orange',
  LEMON = 'lemon',
  KIWI = 'kiwi',

  // Audit types
  OLIVE = 'olive', // GGAP

  // Inspection types
  GUAVA = 'guava', // IPC
  PEACH = 'peach', // PM
  MELON = 'melon', // ILI
  MANDARIN = 'mandarin', // PSI
  ORANGE_600 = 'orange-600', // CLC
  ORANGE_900 = 'orange-900', // SP
  CHERRY_900 = 'cherry-900', // Custom types

  // Other
  SMOKE = 'smoke', // ---
}

export enum EQpIceCubeIconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
}
