import { QpRouterCommandsType } from '@library/models/qp-router.models';
import { Input, Component, ChangeDetectionStrategy } from '@angular/core';
import { Params } from '@angular/router';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'qp-abstract-router-link',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
})
export abstract class QpAbstractRouterLinkComponent {
  @Input()
  public commands: QimaOptionalType<QpRouterCommandsType> = undefined;

  @Input()
  public queryParams: QimaOptionalType<Params> = undefined;
}
