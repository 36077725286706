import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'qp-button-label',
  templateUrl: './qp-button-label.component.html',
  styleUrls: ['./qp-button-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class QpButtonLabelComponent {}
