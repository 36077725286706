// @ts-strict-ignore
import { SERVER_API_URL } from '@admin/app/app.constants';
import { IQpAuthenticationCredentials } from '@library/models/auth/qp-auth.models';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const TOKEN_LIFETIME_IN_DAYS: number = 30;

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
  private readonly _tokenKey = 'authenticationToken';

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _cookieService: CookieService,
    private readonly _qpDateService: QpDateService
  ) {}

  public login$(credentials: IQpAuthenticationCredentials): Observable<string | never> {
    return this._httpClient.post(`${SERVER_API_URL}api/authenticate`, credentials, { observe: 'response' }).pipe(
      map((resp): string | never => {
        const bearerToken = resp.headers.get('Authorization');
        const bearerPrefix = 'Bearer ';

        if (bearerToken?.startsWith(bearerPrefix)) {
          const jwt = bearerToken.slice(bearerPrefix.length, bearerToken.length);

          this.storeAuthenticationToken(jwt, credentials.rememberMe);

          return jwt;
        }

        throw new Error('No bearer token');
      })
    );
  }

  public storeAuthenticationToken(jwt: string, shouldRememberMe: boolean): void {
    if (shouldRememberMe) {
      this._cookieService.put(this._tokenKey, jwt, {
        expires: this._qpDateService.addDays(this._qpDateService.getDate(), TOKEN_LIFETIME_IN_DAYS),
      });
    } else {
      this._cookieService.put(this._tokenKey, jwt);
    }
  }

  public logout$(): Observable<void> {
    return new Observable((observer): void => {
      this._cookieService.remove(this._tokenKey);
      observer.complete();
    });
  }
}
