import { qpIsOptionalTrueStringBoolean } from '@library/functions/states/qp-is-optional-true-string-boolean';
import { QpStringBooleanType } from '@library/models/qp-boolean.models';
import { QimaOptionalType } from '@qima/ngx-qima';

/**
 * @description
 * This "utils" function should be used to convert @Inputs with multiple types to boolean
 * When using @Inputs with shortcuts syntax,
 * This is better to allow passing multiple attributes syntax
 * Some of them are better for performances and to apprehend the code
 * Conversion table:
 * undefined      = true
 * null           = true
 * ''             = true
 * true           = true
 * false          = false
 * true (string)  = true
 * false (string) = false
 * @example
 * This is useful for syntax like:
 * <qp-x xIsDisabled></qp-x>
 * <qp-x xIsDisabled=""></qp-x>
 * <qp-x xIsDisabled="true"></qp-x>
 * <qp-x xIsDisabled="false"></qp-x>
 * <qp-x [xIsDisabled]="true"></qp-x>
 * <qp-x [xIsDisabled]="false"></qp-x>
 * @param {Readonly<QimaOptionalType<QpStringBooleanType>>} isDisabled The disabled state to check
 * @returns {boolean} true when the given disabled state is really disabled
 */
export function qpIsDisabled(isDisabled: Readonly<QimaOptionalType<QpStringBooleanType>>): boolean {
  return qpIsOptionalTrueStringBoolean(isDisabled);
}
