import { isEmpty } from 'lodash/index';
/**
 * @description
 * Check if the given value is nor nil nor an empty string
 * @param {Readonly<QimaOptionalType<string>>} value The value to check
 * @returns {boolean} Return true when the given value is nor nil nor an empty string.
 */
export function qimaIsNotEmptyString(value) {
  return !isEmpty(value);
}
