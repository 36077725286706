import { HttpClient, HttpBackend } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieModule } from 'ngx-cookie';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

/**
 * @description
 * ⚠️ Use {@link HttpBackend} instead of {@link HttpClient} to avoid circular dependency due to interceptors catching the requests
 * @param {HttpBackend} httpBackend The http backend instance
 * @returns {TranslateHttpLoader} The loader
 */
export function httpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend));
}

@NgModule({
  imports: [CookieModule.forRoot(), InfiniteScrollModule],
  exports: [InfiniteScrollModule, ReactiveFormsModule],
})
export class AdminSharedLibsModule {
  public static forRoot(): ModuleWithProviders<AdminSharedLibsModule> {
    return {
      ngModule: AdminSharedLibsModule,
      providers: [TranslateService],
    };
  }
}
