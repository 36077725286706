import { trigger, style, state, transition, animate } from '@angular/animations';
export const QIMA_QUICK_ANIMATION_DURATION = 250;
export const QIMA_SLOW_ANIMATION_DURATION = 500;
export const QIMA_ANIMATION_STYLE = 'ease-in-out';
export const QIMA_QUICK_ACCORDION_ANIMATION = trigger('qimaAccordion', [state('true', style({
  height: '*',
  opacity: 1
})), state('false', style({
  height: '0px',
  opacity: 0
})), transition('false <=> true', animate(`${QIMA_QUICK_ANIMATION_DURATION}ms ${QIMA_ANIMATION_STYLE}`))]);
export const QIMA_SLOW_ACCORDION_ANIMATION = trigger('qimaAccordion', [state('true', style({
  height: '*',
  opacity: 1
})), state('false', style({
  height: '0px',
  opacity: 0
})), transition('false <=> true', animate(`${QIMA_SLOW_ANIMATION_DURATION}ms ${QIMA_ANIMATION_STYLE}`))]);
