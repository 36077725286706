import { EQimaIconUnit } from '../enums/qima-icon-unit.enum';
import { InjectionToken } from '@angular/core';
const DEFAULT_SIZE = 20;
/**
 * @description
 * The token consumed by the {@link QimaIconComponent} to define the default values of its inputs
 * @type {InjectionToken<IQimaIconConfig>}
 */
export const QIMA_ICON_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the icon component', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaIconModule#forRoot} values
     */
    return {
      size: DEFAULT_SIZE,
      unit: EQimaIconUnit.PIXEL
    };
  }
});
