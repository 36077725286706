import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import { times } from 'lodash/index';

/* eslint-disable import/no-named-as-default-member */
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(relativeTime);
/* eslint-enable import/no-named-as-default-member */

const DAYS_IN_WEEK: number = 7;

@Injectable({
  providedIn: 'root',
})
export class QpDateService {
  public static get timezone(): string {
    return dayjs.tz.guess();
  }

  public static get now(): Date {
    return new Date();
  }

  /**
   * @description if you want similar funtionality but working with a Date object,
   * consider {@link QpDateService.getDateWithoutHour}
   * @param {string} dateString (YYYY-MM-DD) date string to be converted
   * @returns {Date} formatted date object without the timezone hour
   */
  public static getDateWithoutTimezoneHour(dateString: string): Date {
    const [year, month, day] = dateString.split('-');

    return new Date(+year, +month - 1, +day);
  }

  /**
   * @deprecated use instead {@link QpDateService.now}
   * @returns {Date} The date
   */
  public getDate(): Date {
    return new Date();
  }

  public getTimestamp(): number {
    return dayjs().valueOf();
  }

  /**
   * @deprecated use instead {@link QpDateService.timezone}
   * @returns {number} The timezone
   */
  public getTimezone(): string {
    return dayjs.tz.guess();
  }

  public toExifDate(date: Date): string {
    return formatDate(date, 'yyyy:MM:dd HH:mm:ss', 'en');
  }

  public toLocaleDate(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd', 'en');
  }

  public getDateWithoutTimezoneConvert(date: Date): string {
    return dayjs(date).format('YYYY-MM-DD');
  }

  public toYearlessDateFormatWithoutTimezoneConvert(date: Date): string {
    return dayjs(date).format('DD-MMM');
  }

  public howManyDaysAgo(date?: Date): number {
    return dayjs().diff(dayjs(date), 'days');
  }

  public getDateDiff(date1: Date, date2: Date): number {
    return dayjs(date1).diff(dayjs(date2));
  }

  public getDateDiffDuration(date1: Date, date2: Date): duration.Duration {
    return dayjs.duration(this.getDateDiff(date1, date2));
  }

  public getDateWithoutHour(date: Date): Date {
    return new Date(dayjs(date).format('l'));
  }

  public getMonday(date: Date): Date {
    const daysSinceLastMonday: number = (date.getDay() || DAYS_IN_WEEK) - 1;
    const dateCopy = new Date(date.getTime());

    dateCopy.setDate(dateCopy.getDate() - daysSinceLastMonday);

    return dateCopy;
  }

  public getFirstDayOfWeek(date: Date): Date {
    return dayjs(date).weekday(0).toDate();
  }

  public getLastDayOfWeek(date: Date): Date {
    return dayjs(date).weekday(6).toDate();
  }

  public getDayOfWeek(date: Date): number {
    return dayjs(date).day();
  }

  public getEndOfMonthDate(date: Date): Date {
    return dayjs(date).endOf('month').toDate();
  }

  public addDays(date: Date, days: number): Date {
    return dayjs(date).add(days, 'days').toDate();
  }

  public subtractDays(date: Date, days: number): Date {
    return dayjs(date).subtract(days, 'days').toDate();
  }

  public addYears(date: Date, years: number): Date {
    return dayjs(date).add(years, 'year').toDate();
  }

  public isToday(date: Date): boolean {
    return this.isSameDay(new Date(), new Date(date));
  }

  public isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  }

  public isSameWeek(date1: Date, date2: Date): boolean {
    const weekStart1 = this.getMonday(date1);
    const weekStart2 = this.getMonday(date2);

    return this.isSameDay(weekStart1, weekStart2);
  }

  public isSameMonth(date1: Date, date2: Date): boolean {
    return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  }

  public getDaysInRange(range: { start: Date; end: Date }): Date[] {
    const startDay = dayjs(range.start);
    const endDay = dayjs(range.end);
    const days: Date[] = [startDay.toDate()];

    times(endDay.diff(startDay, 'days'), (i: number): void => {
      days.push(
        dayjs(startDay)
          .add(i + 1, 'day')
          .toDate()
      );
    });

    return days;
  }

  public isDateExpired(date: Readonly<Date>): boolean {
    const today: Date = new Date();

    today.setHours(0, 0, 0, 0);

    return date.getTime() <= today.getTime();
  }

  public getYesterdayDate(): Date {
    return dayjs().subtract(1, 'day').toDate();
  }

  public getTomorrowDate(): Date {
    return dayjs().add(1, 'day').toDate();
  }

  /**
   * Returns the upcoming first day of the month OR itself if it's the first day of the month
   *
   * @param date
   */
  public getNextFirstDayOfMonth(date: Date): Date {
    if (date.getDate() === 1) {
      return date;
    }

    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
  }

  /**
   * Timezone possible formats:
   * short = "UTC+2", "CEST"... depends on locale
   * long = "Central European Summer Time"
   * offset = "+02:00"
   *
   * @param {Date | string} date Date to parse the timezone for (needed because of DST)
   * @param {string} timezone Input timezone in short or long format
   * @param {'short' | 'long' | 'offset'} format Target format
   * @returns {string} formatted date string
   */
  public formatTimezone(date: Date | string, timezone: string, format: 'short' | 'long' | 'offset' = 'long'): string {
    let formatTemplate: string;

    switch (format) {
      case 'short':
        formatTemplate = 'z';
        break;
      case 'long':
        formatTemplate = 'zzz';
        break;
      case 'offset':
        formatTemplate = 'Z';
        break;
    }

    return dayjs(new Date(date)).tz(timezone).format(formatTemplate);
  }
}
