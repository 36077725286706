import { QIMA_BODY_DEFAULT_FONT_SIZE } from '../../constants/qima-body-default-font-size.constant';
/**
 * @description
 * Convert the given value (in pixel) to em
 * Based on the current body default font size
 * @param {Readonly<number>} value The value in pixels to convert
 * @returns {number} The converted value in em
 */
export function qimaPixelsToEm(value) {
  return value / QIMA_BODY_DEFAULT_FONT_SIZE;
}
