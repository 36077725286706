import { QpAbstractButtonComponent } from '@library/components/qp-button/qp-abstract-button.component';
import { EQpButtonType, EQpButtonSize, EQpButtonWidth, EQpButtonBorderRadiusStyle } from '@library/components/qp-button/qp-button.models';
import { QpAutofocusDirective } from '@library/directives/qp-autofocus/qp-autofocus.directive';
import { QpDisabledDirective } from '@library/directives/qp-disabled/qp-disabled.directive';
import { NgIf, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';

/**
 * @description
 * The encapsulation was removed because we need to alter the style on the projections
 * The projections should be changed based on either or not they are here (as siblings)
 * This could not be achieved through host-context due to direct ancestry tree
 */
@Component({
  selector: 'qp-button',
  templateUrl: './qp-button.component.html',
  styleUrls: ['./qp-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, NgClass, RouterLink, QpDisabledDirective, QpAutofocusDirective, NgTemplateOutlet],
})
export class QpButtonComponent extends QpAbstractButtonComponent {
  /**
   * @type {EQpButtonType}
   * @default {@link EQpButtonType.PRIMARY}
   */
  @Input()
  public type: EQpButtonType = EQpButtonType.PRIMARY;

  /**
   * @type {EQpButtonSize}
   * @default {@link EQpButtonSize.LARGE}
   */
  @Input()
  public size: EQpButtonSize = EQpButtonSize.LARGE;

  /**
   * @type {EQpButtonWidth}
   * @default {@link EQpButtonWidth.COMPACT}
   */
  @Input()
  public width: EQpButtonWidth = EQpButtonWidth.COMPACT;

  /**
   * @type {EQpButtonBorderRadiusStyle}
   * @default {@link EQpButtonBorderRadiusStyle.ALL_SIDES}
   */
  @Input()
  public borderRadiusStyle: EQpButtonBorderRadiusStyle = EQpButtonBorderRadiusStyle.ALL_SIDES;

  /**
   * @description
   * Apply the focus to a button depends of the value
   * @type {boolean}
   * @default false
   */
  @Input()
  public shouldAutoFocus: boolean = false;

  /**
   * @description
   * Apply the type=submit or type=button based on the given value
   * Useful to auto-trigger the submit method on a form
   * @type {boolean}
   * @default false
   */
  @Input()
  public isSubmit: boolean = false;

  public constructor(changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  @HostBinding('class.qp-button--full-width')
  public get className(): boolean {
    return this.width === EQpButtonWidth.FULL;
  }
}
