import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { NgIf } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'qp-icon',
  templateUrl: './qp-icon.component.html',
  styleUrls: ['./qp-icon.component.scss'],
  standalone: true,
  imports: [NgIf, NzIconModule],
})
export class QpIconComponent {
  /**
   * @description
   * For now it's the nzType from ng-zorro
   * Default is undefined
   *
   * @type {QimaOptionalType<EQpIconName>}
   */
  @Input()
  public name: QimaOptionalType<EQpIconName> = undefined;

  /**
   * @description
   * For now it's the nzTheme from ng-zorro
   * Default is 'outline'
   *
   * @type {QimaOptionalType<'fill' | 'outline' | 'twotone'>}
   */
  @Input()
  public theme: QimaOptionalType<'fill' | 'outline' | 'twotone'> = 'outline';
}
