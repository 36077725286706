// @ts-strict-ignore
import { StateStorageService } from '@admin/app/core/auth/state-storage.service';
import { LoginService } from '@admin/app/login/login.service';
import { IQpAuthenticationCredentials } from '@library/models/auth/qp-auth.models';
import { NgIf } from '@angular/common';
import { Component, AfterViewInit, ElementRef } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'admin-login-modal',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FormsModule, TranslateModule],
})
export class LoginModalComponent implements AfterViewInit {
  public hasAuthenticationError: boolean;
  public password: string;
  public shouldRememberMe: boolean;
  public email: string;
  public credentials: IQpAuthenticationCredentials | undefined = undefined;

  private readonly _afterClosed$: Subject<string> = new Subject<string>();

  public get afterClosed$(): Observable<string> {
    return this._afterClosed$.asObservable();
  }

  public constructor(
    private readonly _loginService: LoginService,
    private readonly _stateStorageService: StateStorageService,
    private readonly _elementRef: ElementRef,
    private readonly _router: Router
  ) {}

  public ngAfterViewInit(): void {
    setTimeout((): void => this._elementRef.nativeElement.querySelector('#email').focus(), 0);
  }

  public cancel(): void {
    this.credentials = {
      email: null,
      password: null,
      rememberMe: true,
    };
    this.hasAuthenticationError = false;
    this.closeModal('cancel');
  }

  public login(): void {
    this._loginService
      .login({
        email: this.email.trim(),
        password: this.password,
        rememberMe: this.shouldRememberMe,
      })
      .then((): void => {
        this.hasAuthenticationError = false;
        this.closeModal('login success');

        if (this._router.url === '/register' || /^\/activate\//.test(this._router.url) || /^\/reset\//.test(this._router.url)) {
          void this._router.navigate(['']);
        }

        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
        // since login is successful, go to stored previousState and clear previousState
        const redirect = this._stateStorageService.getUrl();

        if (redirect) {
          this._stateStorageService.storeUrl(null);
          void this._router.navigate([redirect]);
        }
      })
      .catch((): void => {
        this.hasAuthenticationError = true;
      });
  }

  public register(): void {
    this.closeModal('to state register');
    void this._router.navigate(['/register']);
  }

  public requestResetPassword(): void {
    this.closeModal('to state requestReset');
    void this._router.navigate(['/reset', 'request']);
  }

  public closeModal(message: string): void {
    this._afterClosed$.next(message);
    this._afterClosed$.complete();
  }
}
