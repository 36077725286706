export enum EQpButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
  TRANSPARENT = 'transparent',
  SIMPLE = 'simple',
}

export enum EQpButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum EQpButtonWidth {
  COMPACT = 'compact',
  FULL = 'full',
  MIN = 'min',
}

export enum EQpButtonBorderRadiusStyle {
  ALL_SIDES = 'all',
  BOTTOM = 'bottom',
  TOP = 'top',
}
