import { QpIconComponent } from '../qp-icon/qp-icon.component';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'qp-loader',
  templateUrl: './qp-loader.component.html',
  styleUrls: ['./qp-loader.component.scss'],
  standalone: true,
  imports: [QpIconComponent],
})
export class QpLoaderComponent {
  public readonly iconName: EQpIconName.IC_LOADING = EQpIconName.IC_LOADING;
}
