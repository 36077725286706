<!-- Link icon button -->
<ng-container *ngIf="commands && !isDisabled && !isLoading; else isButton">
  <a
    (click)="onButtonClick($event)"
    [ngClass]="['qp-icon-button--size-' + size, 'qp-icon-button--type-' + type, 'qp-icon-button--border-radius-' + borderRadiusStyle]"
    [qpDisabled]="isDisabled"
    [routerLink]="commands"
    class="qp-icon-button"
    qpDisabledClass="qp-icon-button--disabled"
    tabindex="0"
  >
    <ng-container *ngTemplateOutlet="icon"></ng-container>
  </a>
</ng-container>

<!-- Icon button -->
<ng-template #isButton>
  <button
    (click)="onButtonClick($event)"
    [ngClass]="['qp-icon-button--size-' + size, 'qp-icon-button--type-' + type, 'qp-icon-button--border-radius-' + borderRadiusStyle]"
    [qpDisabled]="isDisabled || isLoading"
    class="qp-icon-button"
    qpDisabledClass="qp-icon-button--disabled"
    tabindex="0"
  >
    <ng-container *ngTemplateOutlet="icon"></ng-container>
  </button>
</ng-template>

<!-- Common content -->
<ng-template #icon>
  <ng-container *ngIf="!isLoading">
    <ng-content select="qp-icon"></ng-content>
  </ng-container>
  <ng-content select="qp-loader"></ng-content>
</ng-template>
