import { EQimaPosition } from '../../../models/enums/qima-position.enum';
import { EQimaSnackbarType } from '../enums/qima-snackbar-type.enum';
import { InjectionToken } from '@angular/core';
export const QIMA_SNACKBAR_DURATION_DEFAULT = 2000;
/**
 * @description
 * The token consumed by the {@link QimaSnackbarComponent} to define the default values of its inputs
 * @type {InjectionToken<IQimaSnackbarConfig>}
 */
export const QIMA_SNACKBAR_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the snackbar component', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaSnackbarModule#forRoot} values
     */
    return {
      duration: QIMA_SNACKBAR_DURATION_DEFAULT,
      position: EQimaPosition.TOP,
      type: EQimaSnackbarType.INFORMATION
    };
  }
});
