// @ts-strict-ignore
import { EQpDisabledTabindex } from '@library/directives/qp-disabled/qp-disabled.models';
import { qpIsDisabled } from '@library/functions/states/qp-is-disabled';
import { QpStringBooleanType } from '@library/models/qp-boolean.models';
import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil, isEmpty, clone } from 'lodash/index';

/**
 * @description
 * Applied on a div, the (click) event will still occur
 * On a button for example, it will not because the disabled attribute will not allow to trigger (click)
 */
@Directive({
  selector: '[qpDisabled]',
  standalone: true,
})
export class QpDisabledDirective implements AfterViewInit {
  @Input('tabindex')
  public originalDynamicTabindex: QimaOptionalType<string> = undefined;

  private _isDisabled = false;

  /**
   * @description
   * It will contain on init the current value of the tabindex
   * To reuse it for later
   * If we use the dynamic one, this directive will modify it and so it will be the wrong one
   * @type {QimaOptionalType<string>}
   * @private
   */
  private _originalTabindex: QimaOptionalType<string> = undefined;

  /**
   * @default false
   * @description
   * Conversion table:
   * undefined      = true
   * null           = true
   * ''             = true
   * true           = true
   * false          = false
   * true (string)  = true
   * false (string) = false
   * @example
   * <div qpDisabled></div>           = disabled
   * <div qpDisabled=""></div>        = disabled
   * <div qpDisabled="true"></div>    = disabled
   * <div [qpDisabled]="true"></div>  = disabled
   * <div qpDisabled="false"></div>   = not disabled
   * <div [qpDisabled]="false"></div> = not disabled
   */
  @Input('qpDisabled')
  public set isDisabled(isDisabled: Readonly<QimaOptionalType<QpStringBooleanType>>) {
    this._isDisabled = qpIsDisabled(isDisabled);

    this._updateTabindexAttribute();
    this._updateDisabledAttribute();
    this._updateDisabledClass(this._disabledClass);
  }

  private _disabledClass: QimaOptionalType<string> = undefined;

  /**
   * @default undefined
   * @description
   * Custom class to add when:
   * - [disabled class]{@link QpDisabledDirective#disabledClass} is not empty
   * - [disabled state]{@link QpDisabledDirective#isDisabled} is true
   */
  @Input('qpDisabledClass')
  public set disabledClass(disabledClass: Readonly<QimaOptionalType<string>>) {
    this._updateDisabledClass(disabledClass);

    this._disabledClass = disabledClass;
  }

  public constructor(
    private readonly _elementRef: ElementRef,
    private readonly _renderer: Renderer2
  ) {}

  public ngAfterViewInit(): void {
    this._originalTabindex = clone(this.originalDynamicTabindex);
    this._updateTabindexAttribute();
  }

  private _updateTabindexAttribute(): void {
    const tabindexValue: string | EQpDisabledTabindex = this._isDisabled ? EQpDisabledTabindex.DISABLED : this._getTabindexEnabledValue();

    this._renderer.setAttribute(this._elementRef.nativeElement, 'tabindex', tabindexValue);
  }

  private _updateDisabledAttribute(): void {
    if (this._isDisabled) {
      this._renderer.setAttribute(this._elementRef.nativeElement, 'disabled', '');
    } else {
      this._renderer.removeAttribute(this._elementRef.nativeElement, 'disabled');
    }
  }

  private _updateDisabledClass(newDisabledClass: Readonly<QimaOptionalType<string>>): void {
    this._removeOldDisabledClass();

    if (!isNil(newDisabledClass) && !isEmpty(newDisabledClass)) {
      if (this._isDisabled) {
        this._renderer.addClass(this._elementRef.nativeElement, newDisabledClass);
      }
    }
  }

  private _removeOldDisabledClass(): void {
    if (!isNil(this._disabledClass) && !isEmpty(this._disabledClass)) {
      this._renderer.removeClass(this._elementRef.nativeElement, this._disabledClass);
    }
  }

  private _getTabindexEnabledValue(): string | EQpDisabledTabindex.ENABLED {
    if (!isEmpty(this._originalTabindex) && parseFloat(this._originalTabindex) >= 0) {
      return this._originalTabindex;
    }

    return EQpDisabledTabindex.ENABLED;
  }
}
