import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable({ providedIn: 'root' })
export class StateStorageService {
  public constructor(private readonly _sessionStorageService: SessionStorageService) {}

  public getPreviousState(): unknown {
    return this._sessionStorageService.retrieve('previousState');
  }

  public resetPreviousState(): void {
    this._sessionStorageService.clear('previousState');
  }

  public storePreviousState(previousStateName, previousStateParams): void {
    const previousState = { name: previousStateName, params: previousStateParams };

    this._sessionStorageService.store('previousState', previousState);
  }

  public getDestinationState(): unknown {
    return this._sessionStorageService.retrieve('destinationState');
  }

  public storeUrl(url: string): void {
    this._sessionStorageService.store('previousUrl', url);
  }

  public getUrl(): unknown {
    return this._sessionStorageService.retrieve('previousUrl');
  }

  public storeDestinationState(destinationState, destinationStateParams, fromState): void {
    const destinationInfo = {
      destination: {
        name: destinationState.name,
        data: destinationState.data,
      },
      params: destinationStateParams,
      from: {
        name: fromState.name,
      },
    };

    this._sessionStorageService.store('destinationState', destinationInfo);
  }
}
