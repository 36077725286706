import { EQpLabelType } from '@library/components/qp-label/label-type.enum';
import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

/**
 * @todo make the {@link QpLabelComponent#for} input works with the {@link QpInputTextComponent}
 */
@Component({
  selector: 'qp-label',
  templateUrl: './qp-label.component.html',
  styleUrls: ['./qp-label.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, TranslateModule],
})
export class QpLabelComponent {
  public readonly labelTypes: typeof EQpLabelType = EQpLabelType;

  @Input()
  public type: EQpLabelType = EQpLabelType.DEFAULT;

  @Input()
  public error: boolean = false;

  /**
   * @description
   * Use the same value on an input id to natively give a focus-on-click
   * @type {string}
   * @default ''
   */
  @Input()
  public for: string = '';

  @Input()
  public optional: boolean = false;

  @Input()
  public required: boolean = false;

  /**
   * @description
   * Useful to change the cursor to pointer
   */
  @Input()
  public isClickable: boolean = false;

  /**
   * @description
   * Useful to change the cursor to not-allowed
   */
  @Input()
  public isRelatedInputDisabled: boolean = false;
}
