import { qpIsVndError } from '@library/functions/errors/qp-is-vnd-error';
import { QpObjectType } from '@library/models/qp-object';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QimaSnackbarService, EQimaSnackbarType } from '@qima/ngx-qima';

@Injectable({
  providedIn: 'root',
})
export class QpNotificationBarService {
  public constructor(
    private readonly _qimaSnackbarService: QimaSnackbarService,
    private readonly _translateService: TranslateService
  ) {}

  public success(message: Readonly<string>, params?: Readonly<QpObjectType>): void {
    this._qimaSnackbarService.open({ message: this._translateService.instant(message, params), type: EQimaSnackbarType.SUCCESS });
  }

  public info(message: Readonly<string>, params?: Readonly<QpObjectType>): void {
    this._qimaSnackbarService.open({ message: this._translateService.instant(message, params), type: EQimaSnackbarType.INFORMATION });
  }

  public warning(message: Readonly<string>, params?: Readonly<QpObjectType>): void {
    this._qimaSnackbarService.open({ message: this._translateService.instant(message, params), type: EQimaSnackbarType.WARNING });
  }

  public error(message: Readonly<string>, params?: Readonly<QpObjectType>): void {
    this._qimaSnackbarService.open({ message: this._translateService.instant(message, params), type: EQimaSnackbarType.ERROR });
  }

  public errorWithVNDCheck(message: Readonly<string>, error: Readonly<HttpErrorResponse>, params?: Readonly<QpObjectType>): void {
    if (!qpIsVndError(error)) {
      this.error(message, params);
    }
  }
}
