// @ts-strict-ignore
import { SERVER_API_URL } from '@admin/app/app.constants';
import { Account } from '@admin/app/core/user/account.model';
import { EQpAuthority } from '@library/models/rights/qp-authority.models';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Base64 } from 'js-base64';
import { CookieService } from 'ngx-cookie';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private _userIdentity: Account;
  private _isAuthenticated: boolean = false;
  private readonly _authenticationState$ = new ReplaySubject<Account>(1);

  public constructor(
    private readonly _translateService: TranslateService,
    private readonly _sessionStorageService: SessionStorageService,
    private readonly _httpClient: HttpClient,
    private readonly _cookieService: CookieService
  ) {}

  public save$(account: Partial<Account>): Observable<HttpResponse<void>> {
    const formData = new FormData();

    formData.append('user', new Blob([JSON.stringify(account)], { type: 'application/json' }));

    return this._httpClient.put<void>(`${SERVER_API_URL}api/account`, formData, { observe: 'response' });
  }

  public authenticate(identity): void {
    this._userIdentity = identity;
    this._isAuthenticated = identity !== null;
    this._authenticationState$.next(this._userIdentity);
  }

  public hasAnyAuthority(profiles: string[]): boolean {
    if (!this._isAuthenticated || !this._userIdentity || !this._userIdentity.profiles) {
      return false;
    }

    for (let i = 0; i < profiles.length; i++) {
      if (profiles[i] === EQpAuthority.ROLE_SUPERVISOR && !this.getBrandId()) {
        return false;
      }

      if (this._userIdentity.profiles.includes(EQpAuthority[profiles[i]])) {
        return true;
      }
    }

    return false;
  }

  public hasAuthority(authority: string): Promise<boolean> {
    if (!this._isAuthenticated) {
      return Promise.resolve(false);
    }

    return this.identity().then(
      (id: Account): Promise<boolean> => Promise.resolve(id.profiles?.includes(EQpAuthority[authority])),
      (): Promise<boolean> => Promise.resolve(false)
    );
  }

  public identity(shouldForce: Readonly<boolean> = false): Promise<QimaOptionalType<Account>> {
    if (shouldForce) {
      this._userIdentity = undefined;
    }

    if (this._userIdentity) {
      return Promise.resolve(this._userIdentity);
    }

    return this._fetch$()
      .toPromise()
      .then((account: Account): QimaOptionalType<Account> => {
        if (account) {
          this._userIdentity = account;
          this._isAuthenticated = true;
          const langKey = this._sessionStorageService.retrieve('locale') || this._userIdentity.langKey;

          void this._translateService.use(langKey);
        } else {
          this._userIdentity = null;
          this._isAuthenticated = false;
        }

        this._authenticationState$.next(this._userIdentity);

        return this._userIdentity;
      })
      .catch((): null => {
        this.unsetAccount();

        return null;
      });
  }

  public isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  public getAuthenticationState$(): Observable<Account> {
    return this._authenticationState$.asObservable();
  }

  public unsetAccount(): void {
    this._userIdentity = null;
    this._isAuthenticated = false;
    this._authenticationState$.next(this._userIdentity);
  }

  public getBrandId(): string {
    const payload: string = Base64.decode(this._cookieService.get('authenticationToken')?.split('.')?.[1]);
    const payloadJson = JSON.parse(payload);

    return payloadJson.brandId;
  }

  private _fetch$(): Observable<Account> {
    return this._httpClient.get<Account>(`${SERVER_API_URL}api/account`);
  }
}
