import { LANGUAGES } from '@admin/app/core/language/language.constants';
import { Injectable, RendererFactory2, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageHelperService {
  public renderer: Renderer2 | null = null;
  private readonly _language: BehaviorSubject<string>;

  public constructor(
    private readonly _translateService: TranslateService,
    private readonly _title: Title,
    private readonly _router: Router,
    rootRenderer: RendererFactory2
  ) {
    this._language = new BehaviorSubject<string>(this._translateService.currentLang);
    this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null);
    this._init();
  }

  public getAll(): Promise<string[]> {
    return Promise.resolve(LANGUAGES);
  }

  public get language(): Observable<string> {
    return this._language.asObservable();
  }

  /**
   * Update the window title using params in the following
   * order:
   * 1. titleKey parameter
   * 2. $state.$current.data.pageTitle (current state page title)
   * 3. 'global.title'
   *
   * @param titleKey
   */
  public updateTitle(titleKey?: string): void {
    if (!titleKey) {
      titleKey = this._getPageTitle(this._router.routerState.snapshot.root);
    }

    this._translateService.get(titleKey).subscribe((title): void => {
      this._title.setTitle(title);
    });
  }

  private _init(): void {
    this._translateService.onLangChange.subscribe((): void => {
      this._language.next(this._translateService.currentLang);
      this.renderer?.setAttribute(document.querySelector('html'), 'lang', this._translateService.currentLang);
      this.updateTitle();
    });
  }

  private _getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = routeSnapshot.data?.pageTitle ? routeSnapshot.data.pageTitle : 'adminApp';

    if (routeSnapshot.firstChild) {
      title = this._getPageTitle(routeSnapshot.firstChild) || title;
    }

    return title;
  }
}
