import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/**
 * @deprecated
 * Use instead {@link QimaAutoFocusDirective}
 */
@Directive({
  selector: '[qpAutofocus]',
  standalone: true,
})
export class QpAutofocusDirective extends AutoUnsubscriber implements AfterViewInit {
  /**
   * @description
   * Default to true
   */
  @Input('qpAutofocus')
  public shouldFocus = true;

  /**
   * @description
   * Default to false
   *
   * Works well with {@link QpDisabledDirective}
   */
  @Input('disabled')
  public isDisabled = false;

  public constructor(private readonly _elementRef: ElementRef) {
    super();
  }

  public ngAfterViewInit(): void {
    this.focus();
  }

  /**
   * @param {Readonly<boolean>} shouldForce Very useful when using a template ref to access this API and call manually this method
   */
  public focus(shouldForce: Readonly<boolean> = false): void {
    // The timeout avoid error like ExpressionChangedAfterItHasBeenCheckedError
    setTimeout((): void => {
      if (this.isDisabled === false && (shouldForce === true || this.shouldFocus === true)) {
        this._elementRef.nativeElement.focus();
      }
    });
  }
}
