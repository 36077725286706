<!-- Link button -->
<ng-container *ngIf="commands && !isDisabled && !isLoading; else isButton">
  <a
    (click)="onButtonClick($event)"
    (keydown.enter)="onButtonClick($event)"
    [ngClass]="[
      'qp-button-component--type-' + type,
      'qp-button-component--size-' + size,
      'qp-button-component--width-' + width,
      'qp-button-component--border-radius-' + borderRadiusStyle,
    ]"
    [routerLink]="commands"
    [queryParams]="queryParams"
    [qpDisabled]="isDisabled || isLoading"
    [qpAutofocus]="shouldAutoFocus"
    class="qp-button-component"
    qpDisabledClass="qp-button-component--disabled"
    tabindex="0"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<!-- Button -->
<ng-template #isButton>
  <button
    (click)="onButtonClick($event)"
    [ngClass]="[
      'qp-button-component--type-' + type,
      'qp-button-component--size-' + size,
      'qp-button-component--width-' + width,
      'qp-button-component--border-radius-' + borderRadiusStyle,
    ]"
    [qpDisabled]="isDisabled || isLoading"
    [qpAutofocus]="shouldAutoFocus"
    [type]="isSubmit ? 'submit' : 'button'"
    class="qp-button-component"
    qpDisabledClass="qp-button-component--disabled"
    tabindex="0"
    data-test-id="qp-button"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-template>

<!-- Common content -->
<ng-template #content>
  <ng-container *ngIf="!isLoading">
    <ng-content select="qp-icon"></ng-content>
  </ng-container>
  <ng-content select="qp-loader"></ng-content>
  <ng-content select="qp-button-label"></ng-content>
</ng-template>
