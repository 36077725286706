import { EQimaLoggerLevel } from '../models/enums/qima-logger-level.enum';
import { InjectionToken } from '@angular/core';
/**
 * @description
 * The token consumed by the {@link QimaLoggerService} to define the default values
 * @type {InjectionToken<IQimaLoggerConfig>}
 */
export const QIMA_LOGGER_CONFIG_TOKEN = new InjectionToken('The default values used by the logger', {
  factory() {
    return {
      loggerLevel: EQimaLoggerLevel.WARNING
    };
  }
});
