import { QpStringBooleanType } from '@library/models/qp-boolean.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isBoolean, isString } from 'lodash/index';

/**
 * @description
 * This "utils" function should be used to check if an input is either {true} {false} {'true'} or {'false'}
 * Also include empty case as true
 * Conversion table:
 * undefined      = true
 * null           = true
 * ''             = true
 * true           = true
 * false          = false
 * true (string)  = true
 * false (string) = false
 * @param {Readonly<QimaOptionalType<QpStringBooleanType>>} value The value to check
 * @returns {boolean} Return true when the given value is true or true when optional as well
 */
export function qpIsOptionalTrueStringBoolean(value: Readonly<QimaOptionalType<QpStringBooleanType>>): boolean {
  if (isBoolean(value)) {
    return value;
  } else if (isString(value)) {
    return value !== 'false';
  }

  return true;
}
