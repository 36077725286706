import { AccountService } from '@admin/app/core/auth/account.service';
import { StateStorageService } from '@admin/app/core/auth/state-storage.service';
import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthoritiesGuard implements CanActivate {
  public constructor(
    private readonly _router: Router,
    private readonly _accountService: AccountService,
    private readonly _stateStorageService: StateStorageService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    const authorities = route.data.profiles;

    // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    return this.checkLogin(authorities, state.url);
  }

  public checkLogin(authorities: string[], url: string): Promise<boolean> {
    return this._accountService.identity().then((account): boolean => {
      if (account) {
        if (!authorities || authorities.length === 0) {
          return true;
        }

        const hasAnyAuthority = this._accountService.hasAnyAuthority(authorities);

        if (hasAnyAuthority) {
          return true;
        }

        if (isDevMode()) {
          console.error('User has not any of required authorities: ', authorities);
        }
      }

      this._stateStorageService.storeUrl(url);
      void this._router.navigate(['accessdenied']).then((): void => {
        // only show the login dialog, if the user hasn't logged in yet
        if (!account) {
          void this._router.navigateByUrl('/login');
        }
      });

      return false;
    });
  }
}
