<div class="qp-label-container">
  <label
    [class.qp-label-container__label--clickable]="isClickable"
    [class.qp-label-container__label--disabled]="isRelatedInputDisabled"
    [class.qp-label-container__label--error]="error"
    [for]="for"
    [ngClass]="['qp-label-container__label--' + type]"
    class="qp-label-container__label"
  >
    <ng-content></ng-content>
    <span *ngIf="optional && type !== labelTypes.INFORMATIVE" class="qp-label-container__optional">
      {{ 'global.component.label.optional' | translate }}
    </span>
    <span *ngIf="required" class="qp-label-container__asterisk">* </span>
  </label>
</div>
