import { EQimaThreeSquaresLoaderStyle } from '../enums/qima-three-squares-loader-style.enum';
import { EQimaThreeSquaresLoaderUnit } from '../enums/qima-three-squares-loader-unit.enum';
import { InjectionToken } from '@angular/core';
const DEFAULT_SIZE = 16;
/**
 * @description
 * The token consumed by the {@link QimaThreeSquaresLoaderComponent} to define the default values of its inputs
 * @type {InjectionToken<IQimaThreeSquaresLoaderConfig>}
 */
export const QIMA_THREE_SQUARES_LOADER_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the three squares loader component', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaThreeSquaresLoaderModule#forRoot} values
     */
    return {
      size: DEFAULT_SIZE,
      unit: EQimaThreeSquaresLoaderUnit.PIXEL,
      style: EQimaThreeSquaresLoaderStyle.POOL_500
    };
  }
});
