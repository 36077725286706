import { InjectionToken } from '@angular/core';
/**
 * @description
 * By default, the assets will be loaded from the root path
 * If you load the assets coming from this library from a sub-path, simply set-up this sub-path here
 * @example
 * ngx-qima/assets/
 */
export const QIMA_ASSETS_PATH_TOKEN = new InjectionToken('The default path used to find the assets', {
  factory() {
    return undefined;
  }
});
