import { AccountService } from '@admin/app/core/auth/account.service';
import { AuthServerProvider } from '@admin/app/core/auth/auth-jwt.service';
import { QP_FILTERS_AND_SORTS_QUERY_PARAMS } from '@library/constants/filters-and-sorts-query-params/qp-filters-and-sorts-query-params';
import { IQpAuthenticationCredentials } from '@library/models/auth/qp-auth.models';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({ providedIn: 'root' })
export class LoginService {
  public constructor(
    private readonly _accountService: AccountService,
    private readonly _authServerProvider: AuthServerProvider,
    private readonly _localStorageService: LocalStorageService
  ) {}

  public login(credentials: IQpAuthenticationCredentials, callback?): Promise<unknown> {
    const cb =
      callback ||
      function (): void {
        return;
      };

    this._localStorageService.clear(QP_FILTERS_AND_SORTS_QUERY_PARAMS);

    return new Promise((resolve, reject): void => {
      this._authServerProvider.login$(credentials).subscribe(
        (data): void => {
          this._accountService.identity(true).then((): void => {
            resolve(data);
          });

          return cb();
        },
        (err): void => {
          this.logout();
          reject(err);

          return cb(err);
        }
      );
    });
  }

  public logout(): void {
    this._authServerProvider.logout$().subscribe();
    this._localStorageService.clear(QP_FILTERS_AND_SORTS_QUERY_PARAMS);
    this._accountService.unsetAccount();
  }
}
