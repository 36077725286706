import { QpAbstractButtonComponent } from '@library/components/qp-button/qp-abstract-button.component';
import {
  EQpIconButtonType,
  EQpIconButtonSize,
  EQpIconButtonBorderRadiusStyle,
} from '@library/components/qp-icon-button/qp-icon-button.models';
import { QpDisabledDirective } from '@library/directives/qp-disabled/qp-disabled.directive';
import { NgIf, NgClass, NgTemplateOutlet } from '@angular/common';
import { Input, Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'qp-icon-button',
  templateUrl: './qp-icon-button.component.html',
  styleUrls: ['./qp-icon-button.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, QpDisabledDirective, RouterLink, NgTemplateOutlet],
})
export class QpIconButtonComponent extends QpAbstractButtonComponent {
  @Input()
  public type: EQpIconButtonType = EQpIconButtonType.TRANSPARENT;

  @Input()
  public size: EQpIconButtonSize = EQpIconButtonSize.LARGE;

  @Input()
  public borderRadiusStyle: EQpIconButtonBorderRadiusStyle = EQpIconButtonBorderRadiusStyle.ALL_SIDES;

  public constructor(changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }
}
