<div
  [class.qp-ice-cube-icon--outline]="hasOutline"
  [ngClass]="['qp-ice-cube-icon--color-' + color, 'qp-ice-cube-icon--size-' + size]"
  class="qp-ice-cube-icon"
>
  <qp-icon *ngIf="actionIcon" [name]="actionIcon" class="qp-ice-cube-icon__icon" [class.qp-ice-cube-icon__icon--spinning]="isSpinning">
  </qp-icon>
  <ng-content select="qp-ice-cube-icon-label"></ng-content>
  <ng-content></ng-content>
</div>
