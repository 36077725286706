import { EQimaButtonBorderRadiusStyle } from '../enums/qima-button-border-radius-style.enum';
import { EQimaButtonSize } from '../enums/qima-button-size.enum';
import { EQimaButtonStyle } from '../enums/qima-button-style.enum';
import { EQimaButtonWidth } from '../enums/qima-button-width.enum';
import { InjectionToken } from '@angular/core';
/**
 * @description
 * The token consumed by the {@link QimaButtonComponent} to define the default values of its inputs
 * @type {InjectionToken<IQimaButtonConfig>}
 */
export const QIMA_BUTTON_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the button component', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaButtonModule#forRoot} values
     */
    return {
      isDisabled: false,
      style: EQimaButtonStyle.PRIMARY,
      size: EQimaButtonSize.SMALL,
      width: EQimaButtonWidth.COMPACT,
      borderRadiusStyle: EQimaButtonBorderRadiusStyle.ALL_SIDES,
      shouldAutoFocus: false,
      isLoading: false,
      isSubmit: false,
      isActive: false
    };
  }
});
